const config = {
  default_lat : 41.3825,
  default_lon : 2.176944,
  default_zoom: 12,
  images: {
    maxHeight: 512,
    maxWidth: 1024
  }
};

export default config;
